<template>
  <div class="title mt30">
    <slot name="titleText"></slot>
    <el-button type="primary" @click="handleFaceView()">票面预览</el-button>
    <el-button type="primary" @click="handleFaceDownload()">票面下载</el-button>
  </div>
</template>

<script>
export default {
  name: "workbenchPublicTitle",
  props: ['superInfoList'],
  methods: {
    // 票面预览
    async handleFaceView(){
      let params = {
        paperBagNo: this.superInfoList[0].packageNo,
        section: this.superInfoList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceView', this.$qs.stringify(params), {responseType: 'blob'});
      if (res.data.type === "application/pdf"){
        const blob = res.data;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(blob);
        link.click()
        URL.revokeObjectURL(link.href);
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
    // 票面下载
    async handleFaceDownload(){
      let params = {
        paperBagNo: this.superInfoList[0].packageNo,
        section: this.superInfoList[0].ticketRange
      }
      const res = await this.$auth.post('/cpiaoju-ticket/common/ticketFaceDownload', this.$qs.stringify(params), {responseType: 'blob'});
      console.log(res)
      if (res.headers['content-disposition']){
        const blob = res.data;
        const fileName = window.decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]); //这是下载的关键
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName
        console.log(link)
        link.click()
        URL.revokeObjectURL(url)
      }else{
        return this.$message.error('暂无票面信息')
      }
    },
  }
}
</script>

<style lang="less" scoped>
  /*标题*/
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
</style>