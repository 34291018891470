<template>
  <div>
    <!-- 质押信息 -->
    <el-card>
      <el-descriptions title="质押信息" :column="2">
        <el-descriptions-item label="出质人">{{pledgeInfoMore.debtor}}</el-descriptions-item>
        <el-descriptions-item label="出质人账号">{{pledgeInfoMore.debtorAccNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{pledgeInfoMore.debtorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.debtorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质权人">{{pledgeInfoMore.creditor}}</el-descriptions-item>
        <el-descriptions-item label="质权人账号" v-if="pledgeInfoMore.creditorAcctNo">{{pledgeInfoMore.creditorAcctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行" v-if="pledgeInfoMore.creditorAcctBankName">{{pledgeInfoMore.creditorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.creditorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质押金额（元）">{{pledgeInfoMore.pledgeAmt}}</el-descriptions-item>
        <el-descriptions-item label="申请日期">{{pledgeInfoMore.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="转让标记">{{pledgeInfoMore.transferFlag}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{pledgeInfoMore.remark == '' ? '无' : pledgeInfoMore.remark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 原提示付款申请操作编号 -->
    <el-card>
      <el-descriptions title="原质押申请操作编号" :column="2">
        <el-descriptions-item label="操作编号">{{zysOperNo}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="messageDialogVisible = true">确定提交</el-button>
		</div>

    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleThroughSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>

    <!-- 成功弹框 -->
		<el-dialog :visible.sync="successDialogVisible"
		           width="50%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="successDialogClosed">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  // 倒计时按钮
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: 'PledgeRecallStep',
    components: {
      WorkbenchPublicTitle,
      DelayButton
    },
    mounted(){
      // 业务记录id --- 列表传过来的
      this.ticketBizId = this.$route.query.ticketBizId
      // 获取信息
      this.getInfo()
    },
    data(){
      return {
        ticketBizId: '', // 业务记录id --- 列表传过来的
        handlerTypeCode: '', // 操作类型 --- 短信验证
        handlerNodeCode: '', // 节点状态 --- 短信验证
        zysOperNo:'', // 原质押申请操作编号	
        pledgeInfoMore: {}, // 质押信息	
        superInfoList: [], // 票据基本信息(公共组件) - 票面预览/下载
        mobile: this.$store.state.getUser, // 获取vuex中存的手机号
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        successDialogVisible: false, // 控制同意付款应答弹框的显示与隐藏
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
      }
    },
    methods: {
      // 获取信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/zy/mange/cancel/info/${this.ticketBizId}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.handlerTypeCode = res.data.handlerTypeCode
        this.handlerNodeCode = res.data.handlerNodeCode
        this.zysOperNo = res.data.zysOperNo
        this.pledgeInfoMore = res.data.pledgeInfoMore
        this.superInfoList = res.data.ticketBaseInfoList
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'pledgeRecallList'})
      },
      // 短信提交
      handleThroughSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketBizId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if(res.code === 200){
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/zy/mange/cancel/submit', this.$qs.stringify({ticketBizId: this.ticketBizId}))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.messageDialogVisible = false
            this.successDialogVisible = true
            this.copyCode = result.data.handlerNo
            this.copyText = result.data.text
          }else {
            this.$message.error(res.msg)
            this.messageDialogVisible = true
          }
        })
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 成功弹框关闭
      successDialogClosed(){
        this.$router.push({name: 'pledgeRecallList'})
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .el-descriptions{
    /deep/ .el-descriptions-item__cell{
      padding-bottom: 25px !important;
    }
  }
  // 按钮
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
  // 短信验证框
  .message-form{
     .verify-code{
      width: 220px;
    }
    /deep/ .el-form-item__error{
      left: 90px !important;
    }
  }
  /*同意收票弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
</style>